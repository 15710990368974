/*
 *   Copyright 2018 Amazon.com, Inc. or its affiliates. All Rights Reserved.

 *  Licensed under the Apache License, Version 2.0 (the "License").
 *  You may not use this file except in compliance with the License.
 *  A copy of the License is located at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 *  or in the "license" file accompanying this file. This file is distributed
 *  on an "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either
 *  express or implied. See the License for the specific language governing
 *  permissions and limitations under the License.
 */
 .row {
    max-width: 96.875rem;
    margin-left: auto;
    margin-right: auto
}

.row:after,
.row:before {
    content: ' ';
    display: table
}

.row:after {
    clear: both
}

.row.collapse>.column,
.row.collapse>.columns {
    padding-left: 0;
    padding-right: 0
}

.row .row {
    max-width: none;
    margin-left: -.625rem;
    margin-right: -.625rem
}

@media screen and (min-width:40em) {
    .row .row {
        margin-left: -.9375rem;
        margin-right: -.9375rem
    }
}

.row .row.collapse {
    margin-left: 0;
    margin-right: 0
}

.row.expanded {
    max-width: none
}

.row.expanded .row {
    margin-left: auto;
    margin-right: auto
}

.column,
.columns {
    width: 100%;
    float: left;
    padding-left: .625rem;
    padding-right: .625rem
}

@media screen and (min-width:40em) {
    .column,
    .columns {
        padding-left: .9375rem;
        padding-right: .9375rem
    }
}

.column:last-child:not(:first-child),
.columns:last-child:not(:first-child) {
    float: right
}

.column.end:last-child:last-child,
.end.columns:last-child:last-child {
    float: left
}

.column.row.row,
.row.row.columns {
    float: none
}

.row .column.row.row,
.row .row.row.columns {
    padding-left: 0;
    padding-right: 0;
    margin-left: 0;
    margin-right: 0
}

.small-1 {
    width: 8.3333333333%
}

.small-push-1 {
    position: relative;
    left: 8.3333333333%
}

.small-pull-1 {
    position: relative;
    left: -8.3333333333%
}

.small-offset-0 {
    margin-left: 0
}

.small-2 {
    width: 16.6666666667%
}

.small-push-2 {
    position: relative;
    left: 16.6666666667%
}

.small-pull-2 {
    position: relative;
    left: -16.6666666667%
}

.small-offset-1 {
    margin-left: 8.3333333333%
}

.small-3 {
    width: 25%
}

.small-push-3 {
    position: relative;
    left: 25%
}

.small-pull-3 {
    position: relative;
    left: -25%
}

.small-offset-2 {
    margin-left: 16.6666666667%
}

.small-4 {
    width: 33.3333333333%
}

.small-push-4 {
    position: relative;
    left: 33.3333333333%
}

.small-pull-4 {
    position: relative;
    left: -33.3333333333%
}

.small-offset-3 {
    margin-left: 25%
}

.small-5 {
    width: 41.6666666667%
}

.small-push-5 {
    position: relative;
    left: 41.6666666667%
}

.small-pull-5 {
    position: relative;
    left: -41.6666666667%
}

.small-offset-4 {
    margin-left: 33.3333333333%
}

.small-6 {
    width: 50%
}

.small-push-6 {
    position: relative;
    left: 50%
}

.small-pull-6 {
    position: relative;
    left: -50%
}

.small-offset-5 {
    margin-left: 41.6666666667%
}

.small-7 {
    width: 58.3333333333%
}

.small-push-7 {
    position: relative;
    left: 58.3333333333%
}

.small-pull-7 {
    position: relative;
    left: -58.3333333333%
}

.small-offset-6 {
    margin-left: 50%
}

.small-8 {
    width: 66.6666666667%
}

.small-push-8 {
    position: relative;
    left: 66.6666666667%
}

.small-pull-8 {
    position: relative;
    left: -66.6666666667%
}

.small-offset-7 {
    margin-left: 58.3333333333%
}

.small-9 {
    width: 75%
}

.small-push-9 {
    position: relative;
    left: 75%
}

.small-pull-9 {
    position: relative;
    left: -75%
}

.small-offset-8 {
    margin-left: 66.6666666667%
}

.small-10 {
    width: 83.3333333333%
}

.small-push-10 {
    position: relative;
    left: 83.3333333333%
}

.small-pull-10 {
    position: relative;
    left: -83.3333333333%
}

.small-offset-9 {
    margin-left: 75%
}

.small-11 {
    width: 91.6666666667%
}

.small-push-11 {
    position: relative;
    left: 91.6666666667%
}

.small-pull-11 {
    position: relative;
    left: -91.6666666667%
}

.small-offset-10 {
    margin-left: 83.3333333333%
}

.small-12 {
    width: 100%
}

.small-offset-11 {
    margin-left: 91.6666666667%
}

.small-up-1>.column,
.small-up-1>.columns {
    width: 100%;
    float: left
}

.small-up-1>.column:nth-of-type(1n),
.small-up-1>.columns:nth-of-type(1n) {
    clear: none
}

.small-up-1>.column:nth-of-type(1n+1),
.small-up-1>.columns:nth-of-type(1n+1) {
    clear: both
}

.small-up-1>.column:last-child,
.small-up-1>.columns:last-child {
    float: left
}

.small-up-2>.column,
.small-up-2>.columns {
    width: 50%;
    float: left
}

.small-up-2>.column:nth-of-type(1n),
.small-up-2>.columns:nth-of-type(1n) {
    clear: none
}

.small-up-2>.column:nth-of-type(2n+1),
.small-up-2>.columns:nth-of-type(2n+1) {
    clear: both
}

.small-up-2>.column:last-child,
.small-up-2>.columns:last-child {
    float: left
}

.small-up-3>.column,
.small-up-3>.columns {
    width: 33.3333333333%;
    float: left
}

.small-up-3>.column:nth-of-type(1n),
.small-up-3>.columns:nth-of-type(1n) {
    clear: none
}

.small-up-3>.column:nth-of-type(3n+1),
.small-up-3>.columns:nth-of-type(3n+1) {
    clear: both
}

.small-up-3>.column:last-child,
.small-up-3>.columns:last-child {
    float: left
}

.small-up-4>.column,
.small-up-4>.columns {
    width: 25%;
    float: left
}

.small-up-4>.column:nth-of-type(1n),
.small-up-4>.columns:nth-of-type(1n) {
    clear: none
}

.small-up-4>.column:nth-of-type(4n+1),
.small-up-4>.columns:nth-of-type(4n+1) {
    clear: both
}

.small-up-4>.column:last-child,
.small-up-4>.columns:last-child {
    float: left
}

.small-up-5>.column,
.small-up-5>.columns {
    width: 20%;
    float: left
}

.small-up-5>.column:nth-of-type(1n),
.small-up-5>.columns:nth-of-type(1n) {
    clear: none
}

.small-up-5>.column:nth-of-type(5n+1),
.small-up-5>.columns:nth-of-type(5n+1) {
    clear: both
}

.small-up-5>.column:last-child,
.small-up-5>.columns:last-child {
    float: left
}

.small-up-6>.column,
.small-up-6>.columns {
    width: 16.6666666667%;
    float: left
}

.small-up-6>.column:nth-of-type(1n),
.small-up-6>.columns:nth-of-type(1n) {
    clear: none
}

.small-up-6>.column:nth-of-type(6n+1),
.small-up-6>.columns:nth-of-type(6n+1) {
    clear: both
}

.small-up-6>.column:last-child,
.small-up-6>.columns:last-child {
    float: left
}

.small-up-7>.column,
.small-up-7>.columns {
    width: 14.2857142857%;
    float: left
}

.small-up-7>.column:nth-of-type(1n),
.small-up-7>.columns:nth-of-type(1n) {
    clear: none
}

.small-up-7>.column:nth-of-type(7n+1),
.small-up-7>.columns:nth-of-type(7n+1) {
    clear: both
}

.small-up-7>.column:last-child,
.small-up-7>.columns:last-child {
    float: left
}

.small-up-8>.column,
.small-up-8>.columns {
    width: 12.5%;
    float: left
}

.small-up-8>.column:nth-of-type(1n),
.small-up-8>.columns:nth-of-type(1n) {
    clear: none
}

.small-up-8>.column:nth-of-type(8n+1),
.small-up-8>.columns:nth-of-type(8n+1) {
    clear: both
}

.small-up-8>.column:last-child,
.small-up-8>.columns:last-child {
    float: left
}

.small-collapse>.column,
.small-collapse>.columns {
    padding-left: 0;
    padding-right: 0
}

.expanded.row .small-collapse.row,
.small-collapse .row {
    margin-left: 0;
    margin-right: 0
}

.small-uncollapse>.column,
.small-uncollapse>.columns {
    padding-left: .625rem;
    padding-right: .625rem
}

.small-centered {
    float: none;
    margin-left: auto;
    margin-right: auto
}

.small-pull-0,
.small-push-0,
.small-uncentered {
    position: static;
    margin-left: 0;
    margin-right: 0;
    float: left
}

@media screen and (min-width:40em) {
    .medium-1 {
        width: 8.3333333333%
    }
    .medium-push-1 {
        position: relative;
        left: 8.3333333333%
    }
    .medium-pull-1 {
        position: relative;
        left: -8.3333333333%
    }
    .medium-offset-0 {
        margin-left: 0
    }
    .medium-2 {
        width: 16.6666666667%
    }
    .medium-push-2 {
        position: relative;
        left: 16.6666666667%
    }
    .medium-pull-2 {
        position: relative;
        left: -16.6666666667%
    }
    .medium-offset-1 {
        margin-left: 8.3333333333%
    }
    .medium-3 {
        width: 25%
    }
    .medium-push-3 {
        position: relative;
        left: 25%
    }
    .medium-pull-3 {
        position: relative;
        left: -25%
    }
    .medium-offset-2 {
        margin-left: 16.6666666667%
    }
    .medium-4 {
        width: 33.3333333333%
    }
    .medium-push-4 {
        position: relative;
        left: 33.3333333333%
    }
    .medium-pull-4 {
        position: relative;
        left: -33.3333333333%
    }
    .medium-offset-3 {
        margin-left: 25%
    }
    .medium-5 {
        width: 41.6666666667%
    }
    .medium-push-5 {
        position: relative;
        left: 41.6666666667%
    }
    .medium-pull-5 {
        position: relative;
        left: -41.6666666667%
    }
    .medium-offset-4 {
        margin-left: 33.3333333333%
    }
    .medium-6 {
        width: 50%
    }
    .medium-push-6 {
        position: relative;
        left: 50%
    }
    .medium-pull-6 {
        position: relative;
        left: -50%
    }
    .medium-offset-5 {
        margin-left: 41.6666666667%
    }
    .medium-7 {
        width: 58.3333333333%
    }
    .medium-push-7 {
        position: relative;
        left: 58.3333333333%
    }
    .medium-pull-7 {
        position: relative;
        left: -58.3333333333%
    }
    .medium-offset-6 {
        margin-left: 50%
    }
    .medium-8 {
        width: 66.6666666667%
    }
    .medium-push-8 {
        position: relative;
        left: 66.6666666667%
    }
    .medium-pull-8 {
        position: relative;
        left: -66.6666666667%
    }
    .medium-offset-7 {
        margin-left: 58.3333333333%
    }
    .medium-9 {
        width: 75%
    }
    .medium-push-9 {
        position: relative;
        left: 75%
    }
    .medium-pull-9 {
        position: relative;
        left: -75%
    }
    .medium-offset-8 {
        margin-left: 66.6666666667%
    }
    .medium-10 {
        width: 83.3333333333%
    }
    .medium-push-10 {
        position: relative;
        left: 83.3333333333%
    }
    .medium-pull-10 {
        position: relative;
        left: -83.3333333333%
    }
    .medium-offset-9 {
        margin-left: 75%
    }
    .medium-11 {
        width: 91.6666666667%
    }
    .medium-push-11 {
        position: relative;
        left: 91.6666666667%
    }
    .medium-pull-11 {
        position: relative;
        left: -91.6666666667%
    }
    .medium-offset-10 {
        margin-left: 83.3333333333%
    }
    .medium-12 {
        width: 100%
    }
    .medium-offset-11 {
        margin-left: 91.6666666667%
    }
    .medium-up-1>.column,
    .medium-up-1>.columns {
        width: 100%;
        float: left
    }
    .medium-up-1>.column:nth-of-type(1n),
    .medium-up-1>.columns:nth-of-type(1n) {
        clear: none
    }
    .medium-up-1>.column:nth-of-type(1n+1),
    .medium-up-1>.columns:nth-of-type(1n+1) {
        clear: both
    }
    .medium-up-1>.column:last-child,
    .medium-up-1>.columns:last-child {
        float: left
    }
    .medium-up-2>.column,
    .medium-up-2>.columns {
        width: 50%;
        float: left
    }
    .medium-up-2>.column:nth-of-type(1n),
    .medium-up-2>.columns:nth-of-type(1n) {
        clear: none
    }
    .medium-up-2>.column:nth-of-type(2n+1),
    .medium-up-2>.columns:nth-of-type(2n+1) {
        clear: both
    }
    .medium-up-2>.column:last-child,
    .medium-up-2>.columns:last-child {
        float: left
    }
    .medium-up-3>.column,
    .medium-up-3>.columns {
        width: 33.3333333333%;
        float: left
    }
    .medium-up-3>.column:nth-of-type(1n),
    .medium-up-3>.columns:nth-of-type(1n) {
        clear: none
    }
    .medium-up-3>.column:nth-of-type(3n+1),
    .medium-up-3>.columns:nth-of-type(3n+1) {
        clear: both
    }
    .medium-up-3>.column:last-child,
    .medium-up-3>.columns:last-child {
        float: left
    }
    .medium-up-4>.column,
    .medium-up-4>.columns {
        width: 25%;
        float: left
    }
    .medium-up-4>.column:nth-of-type(1n),
    .medium-up-4>.columns:nth-of-type(1n) {
        clear: none
    }
    .medium-up-4>.column:nth-of-type(4n+1),
    .medium-up-4>.columns:nth-of-type(4n+1) {
        clear: both
    }
    .medium-up-4>.column:last-child,
    .medium-up-4>.columns:last-child {
        float: left
    }
    .medium-up-5>.column,
    .medium-up-5>.columns {
        width: 20%;
        float: left
    }
    .medium-up-5>.column:nth-of-type(1n),
    .medium-up-5>.columns:nth-of-type(1n) {
        clear: none
    }
    .medium-up-5>.column:nth-of-type(5n+1),
    .medium-up-5>.columns:nth-of-type(5n+1) {
        clear: both
    }
    .medium-up-5>.column:last-child,
    .medium-up-5>.columns:last-child {
        float: left
    }
    .medium-up-6>.column,
    .medium-up-6>.columns {
        width: 16.6666666667%;
        float: left
    }
    .medium-up-6>.column:nth-of-type(1n),
    .medium-up-6>.columns:nth-of-type(1n) {
        clear: none
    }
    .medium-up-6>.column:nth-of-type(6n+1),
    .medium-up-6>.columns:nth-of-type(6n+1) {
        clear: both
    }
    .medium-up-6>.column:last-child,
    .medium-up-6>.columns:last-child {
        float: left
    }
    .medium-up-7>.column,
    .medium-up-7>.columns {
        width: 14.2857142857%;
        float: left
    }
    .medium-up-7>.column:nth-of-type(1n),
    .medium-up-7>.columns:nth-of-type(1n) {
        clear: none
    }
    .medium-up-7>.column:nth-of-type(7n+1),
    .medium-up-7>.columns:nth-of-type(7n+1) {
        clear: both
    }
    .medium-up-7>.column:last-child,
    .medium-up-7>.columns:last-child {
        float: left
    }
    .medium-up-8>.column,
    .medium-up-8>.columns {
        width: 12.5%;
        float: left
    }
    .medium-up-8>.column:nth-of-type(1n),
    .medium-up-8>.columns:nth-of-type(1n) {
        clear: none
    }
    .medium-up-8>.column:nth-of-type(8n+1),
    .medium-up-8>.columns:nth-of-type(8n+1) {
        clear: both
    }
    .medium-up-8>.column:last-child,
    .medium-up-8>.columns:last-child {
        float: left
    }
    .medium-collapse>.column,
    .medium-collapse>.columns {
        padding-left: 0;
        padding-right: 0
    }
    .expanded.row .medium-collapse.row,
    .medium-collapse .row {
        margin-left: 0;
        margin-right: 0
    }
    .medium-uncollapse>.column,
    .medium-uncollapse>.columns {
        padding-left: .9375rem;
        padding-right: .9375rem
    }
    .medium-centered {
        float: none;
        margin-left: auto;
        margin-right: auto
    }
    .medium-pull-0,
    .medium-push-0,
    .medium-uncentered {
        position: static;
        margin-left: 0;
        margin-right: 0;
        float: left
    }
}

@media screen and (min-width:64em) {
    .large-1 {
        width: 8.3333333333%
    }
    .large-push-1 {
        position: relative;
        left: 8.3333333333%
    }
    .large-pull-1 {
        position: relative;
        left: -8.3333333333%
    }
    .large-offset-0 {
        margin-left: 0
    }
    .large-2 {
        width: 16.6666666667%
    }
    .large-push-2 {
        position: relative;
        left: 16.6666666667%
    }
    .large-pull-2 {
        position: relative;
        left: -16.6666666667%
    }
    .large-offset-1 {
        margin-left: 8.3333333333%
    }
    .large-3 {
        width: 25%
    }
    .large-push-3 {
        position: relative;
        left: 25%
    }
    .large-pull-3 {
        position: relative;
        left: -25%
    }
    .large-offset-2 {
        margin-left: 16.6666666667%
    }
    .large-4 {
        width: 33.3333333333%
    }
    .large-push-4 {
        position: relative;
        left: 33.3333333333%
    }
    .large-pull-4 {
        position: relative;
        left: -33.3333333333%
    }
    .large-offset-3 {
        margin-left: 25%
    }
    .large-5 {
        width: 41.6666666667%
    }
    .large-push-5 {
        position: relative;
        left: 41.6666666667%
    }
    .large-pull-5 {
        position: relative;
        left: -41.6666666667%
    }
    .large-offset-4 {
        margin-left: 33.3333333333%
    }
    .large-6 {
        width: 50%
    }
    .large-push-6 {
        position: relative;
        left: 50%
    }
    .large-pull-6 {
        position: relative;
        left: -50%
    }
    .large-offset-5 {
        margin-left: 41.6666666667%
    }
    .large-7 {
        width: 58.3333333333%
    }
    .large-push-7 {
        position: relative;
        left: 58.3333333333%
    }
    .large-pull-7 {
        position: relative;
        left: -58.3333333333%
    }
    .large-offset-6 {
        margin-left: 50%
    }
    .large-8 {
        width: 66.6666666667%
    }
    .large-push-8 {
        position: relative;
        left: 66.6666666667%
    }
    .large-pull-8 {
        position: relative;
        left: -66.6666666667%
    }
    .large-offset-7 {
        margin-left: 58.3333333333%
    }
    .large-9 {
        width: 75%
    }
    .large-push-9 {
        position: relative;
        left: 75%
    }
    .large-pull-9 {
        position: relative;
        left: -75%
    }
    .large-offset-8 {
        margin-left: 66.6666666667%
    }
    .large-10 {
        width: 83.3333333333%
    }
    .large-push-10 {
        position: relative;
        left: 83.3333333333%
    }
    .large-pull-10 {
        position: relative;
        left: -83.3333333333%
    }
    .large-offset-9 {
        margin-left: 75%
    }
    .large-11 {
        width: 91.6666666667%
    }
    .large-push-11 {
        position: relative;
        left: 91.6666666667%
    }
    .large-pull-11 {
        position: relative;
        left: -91.6666666667%
    }
    .large-offset-10 {
        margin-left: 83.3333333333%
    }
    .large-12 {
        width: 100%
    }
    .large-offset-11 {
        margin-left: 91.6666666667%
    }
    .large-up-1>.column,
    .large-up-1>.columns {
        width: 100%;
        float: left
    }
    .large-up-1>.column:nth-of-type(1n),
    .large-up-1>.columns:nth-of-type(1n) {
        clear: none
    }
    .large-up-1>.column:nth-of-type(1n+1),
    .large-up-1>.columns:nth-of-type(1n+1) {
        clear: both
    }
    .large-up-1>.column:last-child,
    .large-up-1>.columns:last-child {
        float: left
    }
    .large-up-2>.column,
    .large-up-2>.columns {
        width: 50%;
        float: left
    }
    .large-up-2>.column:nth-of-type(1n),
    .large-up-2>.columns:nth-of-type(1n) {
        clear: none
    }
    .large-up-2>.column:nth-of-type(2n+1),
    .large-up-2>.columns:nth-of-type(2n+1) {
        clear: both
    }
    .large-up-2>.column:last-child,
    .large-up-2>.columns:last-child {
        float: left
    }
    .large-up-3>.column,
    .large-up-3>.columns {
        width: 33.3333333333%;
        float: left
    }
    .large-up-3>.column:nth-of-type(1n),
    .large-up-3>.columns:nth-of-type(1n) {
        clear: none
    }
    .large-up-3>.column:nth-of-type(3n+1),
    .large-up-3>.columns:nth-of-type(3n+1) {
        clear: both
    }
    .large-up-3>.column:last-child,
    .large-up-3>.columns:last-child {
        float: left
    }
    .large-up-4>.column,
    .large-up-4>.columns {
        width: 25%;
        float: left
    }
    .large-up-4>.column:nth-of-type(1n),
    .large-up-4>.columns:nth-of-type(1n) {
        clear: none
    }
    .large-up-4>.column:nth-of-type(4n+1),
    .large-up-4>.columns:nth-of-type(4n+1) {
        clear: both
    }
    .large-up-4>.column:last-child,
    .large-up-4>.columns:last-child {
        float: left
    }
    .large-up-5>.column,
    .large-up-5>.columns {
        width: 20%;
        float: left
    }
    .large-up-5>.column:nth-of-type(1n),
    .large-up-5>.columns:nth-of-type(1n) {
        clear: none
    }
    .large-up-5>.column:nth-of-type(5n+1),
    .large-up-5>.columns:nth-of-type(5n+1) {
        clear: both
    }
    .large-up-5>.column:last-child,
    .large-up-5>.columns:last-child {
        float: left
    }
    .large-up-6>.column,
    .large-up-6>.columns {
        width: 16.6666666667%;
        float: left
    }
    .large-up-6>.column:nth-of-type(1n),
    .large-up-6>.columns:nth-of-type(1n) {
        clear: none
    }
    .large-up-6>.column:nth-of-type(6n+1),
    .large-up-6>.columns:nth-of-type(6n+1) {
        clear: both
    }
    .large-up-6>.column:last-child,
    .large-up-6>.columns:last-child {
        float: left
    }
    .large-up-7>.column,
    .large-up-7>.columns {
        width: 14.2857142857%;
        float: left
    }
    .large-up-7>.column:nth-of-type(1n),
    .large-up-7>.columns:nth-of-type(1n) {
        clear: none
    }
    .large-up-7>.column:nth-of-type(7n+1),
    .large-up-7>.columns:nth-of-type(7n+1) {
        clear: both
    }
    .large-up-7>.column:last-child,
    .large-up-7>.columns:last-child {
        float: left
    }
    .large-up-8>.column,
    .large-up-8>.columns {
        width: 12.5%;
        float: left
    }
    .large-up-8>.column:nth-of-type(1n),
    .large-up-8>.columns:nth-of-type(1n) {
        clear: none
    }
    .large-up-8>.column:nth-of-type(8n+1),
    .large-up-8>.columns:nth-of-type(8n+1) {
        clear: both
    }
    .large-up-8>.column:last-child,
    .large-up-8>.columns:last-child {
        float: left
    }
    .large-collapse>.column,
    .large-collapse>.columns {
        padding-left: 0;
        padding-right: 0
    }
    .expanded.row .large-collapse.row,
    .large-collapse .row {
        margin-left: 0;
        margin-right: 0
    }
    .large-uncollapse>.column,
    .large-uncollapse>.columns {
        padding-left: .9375rem;
        padding-right: .9375rem
    }
    .large-centered {
        float: none;
        margin-left: auto;
        margin-right: auto
    }
    .large-pull-0,
    .large-push-0,
    .large-uncentered {
        position: static;
        margin-left: 0;
        margin-right: 0;
        float: left
    }
}

@media screen and (min-width:75em) {
    .xlarge-1 {
        width: 8.3333333333%
    }
    .xlarge-push-1 {
        position: relative;
        left: 8.3333333333%
    }
    .xlarge-pull-1 {
        position: relative;
        left: -8.3333333333%
    }
    .xlarge-offset-0 {
        margin-left: 0
    }
    .xlarge-2 {
        width: 16.6666666667%
    }
    .xlarge-push-2 {
        position: relative;
        left: 16.6666666667%
    }
    .xlarge-pull-2 {
        position: relative;
        left: -16.6666666667%
    }
    .xlarge-offset-1 {
        margin-left: 8.3333333333%
    }
    .xlarge-3 {
        width: 25%
    }
    .xlarge-push-3 {
        position: relative;
        left: 25%
    }
    .xlarge-pull-3 {
        position: relative;
        left: -25%
    }
    .xlarge-offset-2 {
        margin-left: 16.6666666667%
    }
    .xlarge-4 {
        width: 33.3333333333%
    }
    .xlarge-push-4 {
        position: relative;
        left: 33.3333333333%
    }
    .xlarge-pull-4 {
        position: relative;
        left: -33.3333333333%
    }
    .xlarge-offset-3 {
        margin-left: 25%
    }
    .xlarge-5 {
        width: 41.6666666667%
    }
    .xlarge-push-5 {
        position: relative;
        left: 41.6666666667%
    }
    .xlarge-pull-5 {
        position: relative;
        left: -41.6666666667%
    }
    .xlarge-offset-4 {
        margin-left: 33.3333333333%
    }
    .xlarge-6 {
        width: 50%
    }
    .xlarge-push-6 {
        position: relative;
        left: 50%
    }
    .xlarge-pull-6 {
        position: relative;
        left: -50%
    }
    .xlarge-offset-5 {
        margin-left: 41.6666666667%
    }
    .xlarge-7 {
        width: 58.3333333333%
    }
    .xlarge-push-7 {
        position: relative;
        left: 58.3333333333%
    }
    .xlarge-pull-7 {
        position: relative;
        left: -58.3333333333%
    }
    .xlarge-offset-6 {
        margin-left: 50%
    }
    .xlarge-8 {
        width: 66.6666666667%
    }
    .xlarge-push-8 {
        position: relative;
        left: 66.6666666667%
    }
    .xlarge-pull-8 {
        position: relative;
        left: -66.6666666667%
    }
    .xlarge-offset-7 {
        margin-left: 58.3333333333%
    }
    .xlarge-9 {
        width: 75%
    }
    .xlarge-push-9 {
        position: relative;
        left: 75%
    }
    .xlarge-pull-9 {
        position: relative;
        left: -75%
    }
    .xlarge-offset-8 {
        margin-left: 66.6666666667%
    }
    .xlarge-10 {
        width: 83.3333333333%
    }
    .xlarge-push-10 {
        position: relative;
        left: 83.3333333333%
    }
    .xlarge-pull-10 {
        position: relative;
        left: -83.3333333333%
    }
    .xlarge-offset-9 {
        margin-left: 75%
    }
    .xlarge-11 {
        width: 91.6666666667%
    }
    .xlarge-push-11 {
        position: relative;
        left: 91.6666666667%
    }
    .xlarge-pull-11 {
        position: relative;
        left: -91.6666666667%
    }
    .xlarge-offset-10 {
        margin-left: 83.3333333333%
    }
    .xlarge-12 {
        width: 100%
    }
    .xlarge-offset-11 {
        margin-left: 91.6666666667%
    }
    .xlarge-up-1>.column,
    .xlarge-up-1>.columns {
        width: 100%;
        float: left
    }
    .xlarge-up-1>.column:nth-of-type(1n),
    .xlarge-up-1>.columns:nth-of-type(1n) {
        clear: none
    }
    .xlarge-up-1>.column:nth-of-type(1n+1),
    .xlarge-up-1>.columns:nth-of-type(1n+1) {
        clear: both
    }
    .xlarge-up-1>.column:last-child,
    .xlarge-up-1>.columns:last-child {
        float: left
    }
    .xlarge-up-2>.column,
    .xlarge-up-2>.columns {
        width: 50%;
        float: left
    }
    .xlarge-up-2>.column:nth-of-type(1n),
    .xlarge-up-2>.columns:nth-of-type(1n) {
        clear: none
    }
    .xlarge-up-2>.column:nth-of-type(2n+1),
    .xlarge-up-2>.columns:nth-of-type(2n+1) {
        clear: both
    }
    .xlarge-up-2>.column:last-child,
    .xlarge-up-2>.columns:last-child {
        float: left
    }
    .xlarge-up-3>.column,
    .xlarge-up-3>.columns {
        width: 33.3333333333%;
        float: left
    }
    .xlarge-up-3>.column:nth-of-type(1n),
    .xlarge-up-3>.columns:nth-of-type(1n) {
        clear: none
    }
    .xlarge-up-3>.column:nth-of-type(3n+1),
    .xlarge-up-3>.columns:nth-of-type(3n+1) {
        clear: both
    }
    .xlarge-up-3>.column:last-child,
    .xlarge-up-3>.columns:last-child {
        float: left
    }
    .xlarge-up-4>.column,
    .xlarge-up-4>.columns {
        width: 25%;
        float: left
    }
    .xlarge-up-4>.column:nth-of-type(1n),
    .xlarge-up-4>.columns:nth-of-type(1n) {
        clear: none
    }
    .xlarge-up-4>.column:nth-of-type(4n+1),
    .xlarge-up-4>.columns:nth-of-type(4n+1) {
        clear: both
    }
    .xlarge-up-4>.column:last-child,
    .xlarge-up-4>.columns:last-child {
        float: left
    }
    .xlarge-up-5>.column,
    .xlarge-up-5>.columns {
        width: 20%;
        float: left
    }
    .xlarge-up-5>.column:nth-of-type(1n),
    .xlarge-up-5>.columns:nth-of-type(1n) {
        clear: none
    }
    .xlarge-up-5>.column:nth-of-type(5n+1),
    .xlarge-up-5>.columns:nth-of-type(5n+1) {
        clear: both
    }
    .xlarge-up-5>.column:last-child,
    .xlarge-up-5>.columns:last-child {
        float: left
    }
    .xlarge-up-6>.column,
    .xlarge-up-6>.columns {
        width: 16.6666666667%;
        float: left
    }
    .xlarge-up-6>.column:nth-of-type(1n),
    .xlarge-up-6>.columns:nth-of-type(1n) {
        clear: none
    }
    .xlarge-up-6>.column:nth-of-type(6n+1),
    .xlarge-up-6>.columns:nth-of-type(6n+1) {
        clear: both
    }
    .xlarge-up-6>.column:last-child,
    .xlarge-up-6>.columns:last-child {
        float: left
    }
    .xlarge-up-7>.column,
    .xlarge-up-7>.columns {
        width: 14.2857142857%;
        float: left
    }
    .xlarge-up-7>.column:nth-of-type(1n),
    .xlarge-up-7>.columns:nth-of-type(1n) {
        clear: none
    }
    .xlarge-up-7>.column:nth-of-type(7n+1),
    .xlarge-up-7>.columns:nth-of-type(7n+1) {
        clear: both
    }
    .xlarge-up-7>.column:last-child,
    .xlarge-up-7>.columns:last-child {
        float: left
    }
    .xlarge-up-8>.column,
    .xlarge-up-8>.columns {
        width: 12.5%;
        float: left
    }
    .xlarge-up-8>.column:nth-of-type(1n),
    .xlarge-up-8>.columns:nth-of-type(1n) {
        clear: none
    }
    .xlarge-up-8>.column:nth-of-type(8n+1),
    .xlarge-up-8>.columns:nth-of-type(8n+1) {
        clear: both
    }
    .xlarge-up-8>.column:last-child,
    .xlarge-up-8>.columns:last-child {
        float: left
    }
    .xlarge-collapse>.column,
    .xlarge-collapse>.columns {
        padding-left: 0;
        padding-right: 0
    }
    .expanded.row .xlarge-collapse.row,
    .xlarge-collapse .row {
        margin-left: 0;
        margin-right: 0
    }
    .xlarge-uncollapse>.column,
    .xlarge-uncollapse>.columns {
        padding-left: .9375rem;
        padding-right: .9375rem
    }
    .xlarge-centered {
        float: none;
        margin-left: auto;
        margin-right: auto
    }
    .xlarge-pull-0,
    .xlarge-push-0,
    .xlarge-uncentered {
        position: static;
        margin-left: 0;
        margin-right: 0;
        float: left
    }
}

@media screen and (min-width:90em) {
    .xxlarge-1 {
        width: 8.3333333333%
    }
    .xxlarge-push-1 {
        position: relative;
        left: 8.3333333333%
    }
    .xxlarge-pull-1 {
        position: relative;
        left: -8.3333333333%
    }
    .xxlarge-offset-0 {
        margin-left: 0
    }
    .xxlarge-2 {
        width: 16.6666666667%
    }
    .xxlarge-push-2 {
        position: relative;
        left: 16.6666666667%
    }
    .xxlarge-pull-2 {
        position: relative;
        left: -16.6666666667%
    }
    .xxlarge-offset-1 {
        margin-left: 8.3333333333%
    }
    .xxlarge-3 {
        width: 25%
    }
    .xxlarge-push-3 {
        position: relative;
        left: 25%
    }
    .xxlarge-pull-3 {
        position: relative;
        left: -25%
    }
    .xxlarge-offset-2 {
        margin-left: 16.6666666667%
    }
    .xxlarge-4 {
        width: 33.3333333333%
    }
    .xxlarge-push-4 {
        position: relative;
        left: 33.3333333333%
    }
    .xxlarge-pull-4 {
        position: relative;
        left: -33.3333333333%
    }
    .xxlarge-offset-3 {
        margin-left: 25%
    }
    .xxlarge-5 {
        width: 41.6666666667%
    }
    .xxlarge-push-5 {
        position: relative;
        left: 41.6666666667%
    }
    .xxlarge-pull-5 {
        position: relative;
        left: -41.6666666667%
    }
    .xxlarge-offset-4 {
        margin-left: 33.3333333333%
    }
    .xxlarge-6 {
        width: 50%
    }
    .xxlarge-push-6 {
        position: relative;
        left: 50%
    }
    .xxlarge-pull-6 {
        position: relative;
        left: -50%
    }
    .xxlarge-offset-5 {
        margin-left: 41.6666666667%
    }
    .xxlarge-7 {
        width: 58.3333333333%
    }
    .xxlarge-push-7 {
        position: relative;
        left: 58.3333333333%
    }
    .xxlarge-pull-7 {
        position: relative;
        left: -58.3333333333%
    }
    .xxlarge-offset-6 {
        margin-left: 50%
    }
    .xxlarge-8 {
        width: 66.6666666667%
    }
    .xxlarge-push-8 {
        position: relative;
        left: 66.6666666667%
    }
    .xxlarge-pull-8 {
        position: relative;
        left: -66.6666666667%
    }
    .xxlarge-offset-7 {
        margin-left: 58.3333333333%
    }
    .xxlarge-9 {
        width: 75%
    }
    .xxlarge-push-9 {
        position: relative;
        left: 75%
    }
    .xxlarge-pull-9 {
        position: relative;
        left: -75%
    }
    .xxlarge-offset-8 {
        margin-left: 66.6666666667%
    }
    .xxlarge-10 {
        width: 83.3333333333%
    }
    .xxlarge-push-10 {
        position: relative;
        left: 83.3333333333%
    }
    .xxlarge-pull-10 {
        position: relative;
        left: -83.3333333333%
    }
    .xxlarge-offset-9 {
        margin-left: 75%
    }
    .xxlarge-11 {
        width: 91.6666666667%
    }
    .xxlarge-push-11 {
        position: relative;
        left: 91.6666666667%
    }
    .xxlarge-pull-11 {
        position: relative;
        left: -91.6666666667%
    }
    .xxlarge-offset-10 {
        margin-left: 83.3333333333%
    }
    .xxlarge-12 {
        width: 100%
    }
    .xxlarge-offset-11 {
        margin-left: 91.6666666667%
    }
    .xxlarge-up-1>.column,
    .xxlarge-up-1>.columns {
        width: 100%;
        float: left
    }
    .xxlarge-up-1>.column:nth-of-type(1n),
    .xxlarge-up-1>.columns:nth-of-type(1n) {
        clear: none
    }
    .xxlarge-up-1>.column:nth-of-type(1n+1),
    .xxlarge-up-1>.columns:nth-of-type(1n+1) {
        clear: both
    }
    .xxlarge-up-1>.column:last-child,
    .xxlarge-up-1>.columns:last-child {
        float: left
    }
    .xxlarge-up-2>.column,
    .xxlarge-up-2>.columns {
        width: 50%;
        float: left
    }
    .xxlarge-up-2>.column:nth-of-type(1n),
    .xxlarge-up-2>.columns:nth-of-type(1n) {
        clear: none
    }
    .xxlarge-up-2>.column:nth-of-type(2n+1),
    .xxlarge-up-2>.columns:nth-of-type(2n+1) {
        clear: both
    }
    .xxlarge-up-2>.column:last-child,
    .xxlarge-up-2>.columns:last-child {
        float: left
    }
    .xxlarge-up-3>.column,
    .xxlarge-up-3>.columns {
        width: 33.3333333333%;
        float: left
    }
    .xxlarge-up-3>.column:nth-of-type(1n),
    .xxlarge-up-3>.columns:nth-of-type(1n) {
        clear: none
    }
    .xxlarge-up-3>.column:nth-of-type(3n+1),
    .xxlarge-up-3>.columns:nth-of-type(3n+1) {
        clear: both
    }
    .xxlarge-up-3>.column:last-child,
    .xxlarge-up-3>.columns:last-child {
        float: left
    }
    .xxlarge-up-4>.column,
    .xxlarge-up-4>.columns {
        width: 25%;
        float: left
    }
    .xxlarge-up-4>.column:nth-of-type(1n),
    .xxlarge-up-4>.columns:nth-of-type(1n) {
        clear: none
    }
    .xxlarge-up-4>.column:nth-of-type(4n+1),
    .xxlarge-up-4>.columns:nth-of-type(4n+1) {
        clear: both
    }
    .xxlarge-up-4>.column:last-child,
    .xxlarge-up-4>.columns:last-child {
        float: left
    }
    .xxlarge-up-5>.column,
    .xxlarge-up-5>.columns {
        width: 20%;
        float: left
    }
    .xxlarge-up-5>.column:nth-of-type(1n),
    .xxlarge-up-5>.columns:nth-of-type(1n) {
        clear: none
    }
    .xxlarge-up-5>.column:nth-of-type(5n+1),
    .xxlarge-up-5>.columns:nth-of-type(5n+1) {
        clear: both
    }
    .xxlarge-up-5>.column:last-child,
    .xxlarge-up-5>.columns:last-child {
        float: left
    }
    .xxlarge-up-6>.column,
    .xxlarge-up-6>.columns {
        width: 16.6666666667%;
        float: left
    }
    .xxlarge-up-6>.column:nth-of-type(1n),
    .xxlarge-up-6>.columns:nth-of-type(1n) {
        clear: none
    }
    .xxlarge-up-6>.column:nth-of-type(6n+1),
    .xxlarge-up-6>.columns:nth-of-type(6n+1) {
        clear: both
    }
    .xxlarge-up-6>.column:last-child,
    .xxlarge-up-6>.columns:last-child {
        float: left
    }
    .xxlarge-up-7>.column,
    .xxlarge-up-7>.columns {
        width: 14.2857142857%;
        float: left
    }
    .xxlarge-up-7>.column:nth-of-type(1n),
    .xxlarge-up-7>.columns:nth-of-type(1n) {
        clear: none
    }
    .xxlarge-up-7>.column:nth-of-type(7n+1),
    .xxlarge-up-7>.columns:nth-of-type(7n+1) {
        clear: both
    }
    .xxlarge-up-7>.column:last-child,
    .xxlarge-up-7>.columns:last-child {
        float: left
    }
    .xxlarge-up-8>.column,
    .xxlarge-up-8>.columns {
        width: 12.5%;
        float: left
    }
    .xxlarge-up-8>.column:nth-of-type(1n),
    .xxlarge-up-8>.columns:nth-of-type(1n) {
        clear: none
    }
    .xxlarge-up-8>.column:nth-of-type(8n+1),
    .xxlarge-up-8>.columns:nth-of-type(8n+1) {
        clear: both
    }
    .xxlarge-up-8>.column:last-child,
    .xxlarge-up-8>.columns:last-child {
        float: left
    }
    .xxlarge-collapse>.column,
    .xxlarge-collapse>.columns {
        padding-left: 0;
        padding-right: 0
    }
    .expanded.row .xxlarge-collapse.row,
    .xxlarge-collapse .row {
        margin-left: 0;
        margin-right: 0
    }
    .xxlarge-uncollapse>.column,
    .xxlarge-uncollapse>.columns {
        padding-left: .9375rem;
        padding-right: .9375rem
    }
    .xxlarge-centered {
        float: none;
        margin-left: auto;
        margin-right: auto
    }
    .xxlarge-pull-0,
    .xxlarge-push-0,
    .xxlarge-uncentered {
        position: static;
        margin-left: 0;
        margin-right: 0;
        float: left
    }
}

.footer-nav ul,
.site-nav ul {
    margin: 0;
    padding: 0;
    list-style: none
}

*,
:after,
:before {
    box-sizing: border-box
}

html {
    line-height: 1.4
}

html.menu-opened {
    overflow: hidden
}

body {
    font-family: fairplex-wide, serif;
    font-size: 125%;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale
}

h1,
h2,
h3,
h4,
h5 {
    line-height: 1;
    margin: 0 0 .9375rem;
    text-transform: uppercase;
    font-size: 1.5rem
}

@media screen and (min-width:40em) {
    h1,
    h2,
    h3,
    h4,
    h5 {
        font-size: 2.25rem
    }
}

@media screen and (min-width:64em) {
    h1,
    h2,
    h3,
    h4,
    h5 {
        font-size: 3rem
    }
}

::-webkit-input-placeholder {
    color: hsla(0, 0%, 100%, .4);
    font-style: italic
}

::-moz-placeholder {
    color: hsla(0, 0%, 100%, .4);
    font-style: italic
}

:-ms-input-placeholder {
    color: hsla(0, 0%, 100%, .4);
    font-style: italic
}

a {
    color: #f50856;
    text-decoration: none
}

img {
    max-width: 100%
}

.content {
    font-style: italic
}

.site-nav {
    background: url("/images/star-pattern.png ") 50% hsla(0, 0%, 100%, .2);
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    text-align: center;
    position: fixed;
    top: 0;
    left: 0;
    opacity: 0;
    height: 100vh;
    width: 100%;
    z-index: -1;
    -webkit-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out
}

.menu-opened .site-nav {
    opacity: 1;
    z-index: 100
}

.site-nav li {
    margin: .9375rem 0
}

.site-nav a {
    color: #000;
    font-weight: 700
}

.site-nav a:hover {
    color: #f50856
}

@media screen and (min-width:40em) {
    .site-nav a {
        font-size: 2.5rem
    }
}

@media screen and (min-width:64em) {
    .site-nav a {
        font-size: 3.125rem
    }
}

.btn-menu {
    background: none;
    width: 30px;
    height: 19px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border: none;
    cursor: pointer;
    outline: none;
    position: absolute;
    padding: 0;
    right: 1.25rem;
    top: 1.25rem;
    z-index: 200;
    text-indent: -999em
}

.btn-menu span,
.btn-menu span:after,
.btn-menu span:before {
    cursor: pointer;
    width: 30px;
    height: 3px
}

.btn-menu span {
    display: block;
    position: relative;
    background: #fff;
    -webkit-transition: all 0ms .3s;
    transition: all 0ms .3s
}

.btn-menu span:before {
    bottom: 8px;
    -webkit-transition: bottom .3s cubic-bezier(.23, 1, .32, 1) .3s, -webkit-transform .3s cubic-bezier(.23, 1, .32, 1);
    transition: bottom .3s cubic-bezier(.23, 1, .32, 1) .3s, -webkit-transform .3s cubic-bezier(.23, 1, .32, 1);
    transition: bottom .3s cubic-bezier(.23, 1, .32, 1) .3s, transform .3s cubic-bezier(.23, 1, .32, 1);
    transition: bottom .3s cubic-bezier(.23, 1, .32, 1) .3s, transform .3s cubic-bezier(.23, 1, .32, 1), -webkit-transform .3s cubic-bezier(.23, 1, .32, 1)
}

.btn-menu span:after,
.btn-menu span:before {
    content: "";
    position: absolute;
    left: 0;
    background: #fff
}

.btn-menu span:after {
    top: 8px;
    -webkit-transition: top .3s cubic-bezier(.23, 1, .32, 1) .3s, -webkit-transform .3s cubic-bezier(.23, 1, .32, 1);
    transition: top .3s cubic-bezier(.23, 1, .32, 1) .3s, -webkit-transform .3s cubic-bezier(.23, 1, .32, 1);
    transition: top .3s cubic-bezier(.23, 1, .32, 1) .3s, transform .3s cubic-bezier(.23, 1, .32, 1);
    transition: top .3s cubic-bezier(.23, 1, .32, 1) .3s, transform .3s cubic-bezier(.23, 1, .32, 1), -webkit-transform .3s cubic-bezier(.23, 1, .32, 1)
}

.menu-opened .btn-menu span {
    background: hsla(0, 0%, 100%, 0)
}

.menu-opened .btn-menu span:after {
    background: #000;
    top: 0;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    -webkit-transition: top .3s cubic-bezier(.23, 1, .32, 1), -webkit-transform .3s cubic-bezier(.23, 1, .32, 1) .3s;
    transition: top .3s cubic-bezier(.23, 1, .32, 1), -webkit-transform .3s cubic-bezier(.23, 1, .32, 1) .3s;
    transition: top .3s cubic-bezier(.23, 1, .32, 1), transform .3s cubic-bezier(.23, 1, .32, 1) .3s;
    transition: top .3s cubic-bezier(.23, 1, .32, 1), transform .3s cubic-bezier(.23, 1, .32, 1) .3s, -webkit-transform .3s cubic-bezier(.23, 1, .32, 1) .3s
}

.menu-opened .btn-menu span:before {
    background: #000;
    bottom: 0;
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
    -webkit-transition: bottom .3s cubic-bezier(.23, 1, .32, 1), -webkit-transform .3s cubic-bezier(.23, 1, .32, 1) .3s;
    transition: bottom .3s cubic-bezier(.23, 1, .32, 1), -webkit-transform .3s cubic-bezier(.23, 1, .32, 1) .3s;
    transition: bottom .3s cubic-bezier(.23, 1, .32, 1), transform .3s cubic-bezier(.23, 1, .32, 1) .3s;
    transition: bottom .3s cubic-bezier(.23, 1, .32, 1), transform .3s cubic-bezier(.23, 1, .32, 1) .3s, -webkit-transform .3s cubic-bezier(.23, 1, .32, 1) .3s
}

.site-logo {
    display: block;
    background: url("/images/wr-logo-white.png") no-repeat;
    height: 3.5rem;
    width: 15.1875rem;
    text-indent: -999em;
    margin: 0 auto 2.5rem
}

.site-logo.dark {
     background: url("/images/wr-logo-black.png") no-repeat
}

@media screen and (min-width:64em) {
    .site-logo {
        margin-bottom: 6.25rem
    }
}

.site-footer {
    background: #000;
    padding: 2.5rem 0;
    text-align: center
}

@media screen and (min-width:40em) {
    .site-footer {
        padding: 3.125rem 0 6.25rem
    }
}

.footer-nav {
    margin-bottom: .9375rem
}

.footer-nav ul {
    line-height: 1.5
}

@media screen and (min-width:40em) {
    .footer-nav li {
        display: inline-block;
        margin: 0 .625rem
    }
}

@media screen and (min-width:64em) {
    .footer-nav li {
        margin: 0 1.25rem
    }
}

.footer-nav a {
    color: #fff;
    font-size: 1.125rem
}

.footer-nav a:hover {
    color: #f50856
}

.footer-legal {
    color: #555;
    font-size: 1.125rem
}

.page-home .site-header {
    position: relative
}

.page-home .site-header .title {
    background: url("/images/wr-home-top.jpg") no-repeat top;
    background-size: cover;
    padding-bottom: 50.2753441802%;
    margin: 0;
    text-indent: -999em;
    position: relative
}

@media screen and (min-width:75em) {
    .page-home .site-header .title:after {
        background: -webkit-linear-gradient(top, transparent, rgba(243, 8, 85, .85) 99%, rgba(245, 8, 86, .85));
        background: linear-gradient(180deg, transparent 0, rgba(243, 8, 85, .85) 99%, rgba(245, 8, 86, .85));
        content: '';
        height: 3.75rem;
        width: 100%;
        position: absolute;
        left: 0;
        bottom: 0
    }
}

.page-home .home-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: #fff;
    border: 1px solid transparent;
    border-radius: 1.25rem;
    color: #f50856;
    font-size: 1.25rem;
    text-transform: uppercase;
    padding: .3125rem 1.5625rem;
    position: absolute;
    left: 50%;
    bottom: 5%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    font-weight: 700
}

@media screen and (min-width:40em) {
    .page-home .home-button {
        bottom: 22%
    }
}

.home-about {
    background: #f50856;
    color: #fff;
    padding: 5rem 0;
    text-align: center
}

.home-about-block {
    margin: 2.5rem 0
}

@media screen and (min-width:64em) {
    .home-about-block {
        margin: 5rem 0 0
    }
}

@media screen and (min-width:90em) {
    .home-about-block {
        max-width: 15rem;
        margin-left: auto;
        margin-right: auto
    }
}

.home-about-block .title {
    margin: 0
}

.home-about-block .title:before {
    content: '';
    display: block;
    margin: 0 auto 1.25rem;
    height: 12.75rem
}

@media screen and (min-width:64em) {
    .home-about-block .title {
        font-size: 2.375rem
    }
}

.home-about-block .content {
    margin: 0
}

.home-about-block .icon-download:before {
    background: url("/images/wr-home-block-1.png") no-repeat;
    width: 11rem
}

.home-about-block .icon-unicorn:before {
    background: url("/images/wr-home-block-2.png") no-repeat;
    width: 14.75rem
}

.home-about-block .icon-price:before {
    background: url("/images/wr-home-block-3.png") no-repeat;
    width: 13.75rem
}

.home-about-block .icon-success:before {
    background: url("/images/wr-home-block-4.png") no-repeat;
    width: 14.75rem
}

.home-story {
    background: url("/images/wr-home-about.jpg") no-repeat bottom;
    background-size: cover;
    color: #fff;
    text-align: center;
    padding: 5rem 0
}

@media screen and (min-width:64em) {
    .home-story {
        height: 54.6875rem;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center
    }
}

@media screen and (min-width:75em) {
    .home-story {
        height: 63.75rem
    }
}

.home-sign-up {
    background: #000;
    color: #fff;
    text-align: center;
    padding: 5rem 0
}

.home-sign-up .icon-w {
    max-width: 50%
}

@media screen and (min-width:64em) {
    .home-sign-up .icon-w {
        max-width: 100%
    }
}

.home-sign-up form {
    max-width: 45rem;
    margin: 1.875rem auto;
    overflow: hidden
}

.home-sign-up form input {
    background: #333;
    border: 1px solid transparent;
    color: #fff;
    padding: .3125rem;
    outline: none;
    width: 100%
}

@media screen and (min-width:40em) {
    .home-sign-up form input {
        border-top-left-radius: 40px;
        border-bottom-left-radius: 40px;
        float: left;
        height: 3.4375rem;
        padding: .3125rem 1.875rem;
        width: calc(100% - 230px)
    }
}

.home-sign-up form button {
    background: #fff;
    border: 1px solid transparent;
    color: #000;
    font-weight: 700;
    text-transform: uppercase;
    outline: none;
    width: 100%
}

@media screen and (min-width:40em) {
    .home-sign-up form button {
        border-top-right-radius: 40px;
        border-bottom-right-radius: 40px;
        float: left;
        height: 3.4375rem;
        width: 14.375rem
    }
}

.home-sign-up .apps {
    margin: 1.875rem 0
}

@media screen and (min-width:40em) {
    .home-sign-up .apps a {
        margin: 0 .3125rem
    }
}

.home-sign-up .social a {
    display: inline-block;
    text-indent: -999em;
    height: 1.5rem;
    width: 1.5rem;
    margin: 0 .625rem;
    vertical-align: middle
}

.home-sign-up .social .icon-fb {
    background: url("/images/wr-home-facebook.png") no-repeat;
    height: 1.5625rem;
    width: 1.5625rem
}

.home-sign-up .social .icon-tw {
    background: url("/images/wr-home-twitter.png") no-repeat
}

.home-sign-up .social .icon-ig {
    background: url("/images/wr-home-instagram.png") no-repeat
}

.home-sign-up .social .icon-wc {
    background: url("/images/wr-home-wechat.png") no-repeat;
    width: 1.8125rem
}

.home-sign-up .social .icon-wb {
    background: url("/images/wr-home-weibo.png") no-repeat;
    width: 1.6875rem
}

.home-quote {
    background: url("/images/wr-home-quote.jpg") no-repeat bottom;
    background-size: cover;
    color: #fff;
    text-align: center;
    padding: 5rem 0;
    line-height: .9
}

@media screen and (min-width:40em) {
    .home-quote {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center
    }
}

@media screen and (min-width:64em) {
    .home-quote {
        height: 37.5rem
    }
}

@media screen and (min-width:75em) {
    .home-quote {
        height: 50rem
    }
}

.home-quote .quote {
    text-transform: uppercase;
    font-weight: 900;
    margin-bottom: 2.5rem
}

@media screen and (min-width:40em) {
    .home-quote .quote {
        font-size: 1.875rem
    }
}

@media screen and (min-width:64em) {
    .home-quote .quote {
        font-size: 3.125rem
    }
}

.home-quote .quoter {
    font-style: italic
}

.kraken-callout {
    background: #00bbd3;
    color: #fff;
    text-align: center;
    padding: 5rem 0
}

@media screen and (min-width:40em) {
    .kraken-callout .row {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center
    }
}

.kraken-callout img {
    max-width: 80%;
    margin-bottom: 1.25rem
}

@media screen and (min-width:40em) {
    .kraken-callout img {
        max-width: 100%;
        margin: 0
    }
}

.page-unicorns .site-header {
    background: url("/images/wr-unicorn-header.png") no-repeat top;
    background-size: cover;
    text-align: center;
    padding: 1.875rem 0
}

@media screen and (min-width:64em) {
    .page-unicorns .site-header {
        min-height: 31.25rem
    }
}

@media screen and (min-width:90em) {
    .page-unicorns .site-header {
        min-height: 40.625rem
    }
}

.unicorns-list {
    margin-top: 2.5rem;
    text-align: center
}

@media screen and (min-width:64em) {
    .unicorns-list {
        margin-bottom: 3.75rem
    }
}

.unicorns-list img {
    margin-bottom: 1.25rem
}

.unicorns-list .title {
    margin: 0
}

.unicorns-list .subtitle {
    font-style: italic
}

.unicorns-list .subtitle:after {
    content: '-';
    display: block
}

.unicorns-list .content {
    margin-top: 0
}

.unicorns-list .unicorn {
    overflow: hidden;
    margin-bottom: 1.875rem
}

@media screen and (min-width:40em) {
    .unicorns-list .unicorn {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center
    }
}

.unicorns-list .jimmy .subtitle,
.unicorns-list .jimmy .title {
    color: #f50856
}

.unicorns-list .henry .subtitle,
.unicorns-list .henry .title {
    color: #00bbd3
}

.unicorns-list .veronica .subtitle,
.unicorns-list .veronica .title {
    color: #ffc400
}

.page-investors .top-section {
    background: url("/images/wr-investors-header.png") no-repeat bottom;
    background-size: cover;
    text-align: center;
    padding: 1.875rem 0
}

@media screen and (min-width:64em) {
    .page-investors .top-section {
        padding-bottom: 3.75rem
    }
}

.investors-list {
    color: #fff;
    text-align: center
}

@media screen and (min-width:64em) {
    .investors-list {
        margin-top: 3.75rem
    }
}

.investors-list .investor {
    margin: 0 auto 2.5rem;
    max-width: 25rem
}

@media screen and (min-width:64em) {
    .investors-list .investor {
        max-width: 100%
    }
}

.investors-list .title {
    -webkit-hyphens: auto;
    -ms-hyphens: auto;
    hyphens: auto;
    margin: 0
}

.investors-list .title:before {
    content: '';
    display: block;
    margin: 0 auto 1.25rem;
    height: 10.875rem
}

@media screen and (min-width:40em) {
    .investors-list .title {
        font-size: 1.875rem
    }
}

.investors-list .title.pcp:before {
    background: url("/images/wr-investors-pcp.png") no-repeat 50%;
    width: 8.8125rem
}

.investors-list .title.awesome:before {
    background: url("/images/wr-investors-awesome.png") no-repeat 50%;
    width: 7.75rem
}

.investors-list .title.barn:before {
    background: url("/images/wr-investors-thebarn.png") no-repeat 50%;
    width: 7.4375rem
}

.investors-list .subtitle {
    font-style: italic
}

.board-directors {
    background: #000;
    color: #fff;
    text-align: center
}

@media screen and (min-width:64em) {
    .board-directors {
        padding: 1.875rem 0
    }
}

.board-directors .block {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    padding: 1.25rem;
    min-height: 15.625rem;
    margin-bottom: .9375rem;
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: cover
}

@media screen and (min-width:64em) {
    .board-directors .block {
        margin-bottom: 1.875rem;
        min-height: 24.375rem
    }
}

.board-directors .block:not(.intro) {
    color: #000
}

.board-directors .block:not(.intro) .title {
    margin: 0 0 .625rem
}

@media screen and (min-width:40em) {
    .board-directors .block:not(.intro) .title {
        font-size: 1.875rem
    }
}

.board-directors .block.one {
    background-image: url("/images/wr-investors-1.png")
}

.board-directors .block.two {
    background-image: url("/images/wr-investors-2.png")
}

.board-directors .block.three {
    background-image: url("/images/wr-investors-3.png")
}

.board-directors .block.four {
    background-image: url("/images/wr-investors-4.png")
}

.board-directors .block.five {
    background-image: url("/images/wr-investors-5.png")
}

.board-directors .content {
    margin: 0;
    line-height: 1.1
}

.page-faq .site-header {
    background: url("/images/wr-faq-header.jpg") no-repeat top;
    background-size: cover;
    color: #fff;
    text-align: center;
    padding: 1.875rem 0
}

@media screen and (min-width:64em) {
    .page-faq .site-header {
        min-height: 30rem
    }
}

@media screen and (min-width:75em) {
    .page-faq .site-header {
        min-height: 34.375rem
    }
}

.faq-list {
    padding: 2.5rem 0
}

@media screen and (min-width:40em) {
    .faq-list {
        padding: 3.75rem 0
    }
}

@media screen and (min-width:64em) {
    .faq-list {
        font-size: 1.5rem;
        padding: 5rem 0
    }
}

.faq-list dl {
    margin: 0
}

.faq-list dt {
    font-weight: 700
}

.faq-list dd {
    margin: 0 0 1.875rem
}

.page-apply .site-header {
     background: url("/images/wr-apply-header.png") no-repeat bottom;
     background-size: cover;
     color: #fff;
     text-align: center;
     padding: 1.875rem 0
 }

@media screen and (min-width:64em) {
     .page-apply .site-header {
         min-height: 30rem
     }
 }

@media screen and (min-width:75em) {
     .page-apply .site-header {
         min-height: 34.375rem
     }
 }

.page-apply .content {
     font-style: normal;
     font-weight: 700;
     padding: 2.5rem 0
 }

@media screen and (min-width:64em) {
     .page-apply .content {
         font-size: 1.5rem
     }
 }
